@import 'src/theme';

.container-wrapper {
    padding: 144px 0;
}

.container-wrapper-lg {
    padding: 256px 0;
}

.container-accent-1 {
    background-color: $bgDark;
}

.MuiButton-root {
    font-weight: 600;
    font-size: 12pt !important;
}

.MuiButton-contained {
    color: $text !important;
}

.gradient-btn-primary {
    background: linear-gradient(to left, $primary, $secondary);
}

.gradient-btn-secondary {
    background: $primary-trans !important;
    border-width: 4px !important;
    border-style: solid !important;
    border-color: $primary !important;
}
