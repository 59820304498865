@import 'src/theme';

$black-color: #030509;

#hero {
    position:relative;
    margin: 0;
    padding: 0;
    background-color: $black-color;

    .img-hide {
        overflow: hidden;
    }

    .img-wrapper {
        background-color: $black-color;
        display: block;
        position: relative;
        left: calc(50vw - 1280px);
        width: fit-content;
        text-align: center;
        line-height: 0;
    }

    .content-wrapper {
        width: 100%;
        position: absolute;
        top: 0;

        .title-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: flex-start;
            margin: 85px auto;
            max-width: 768px;
            padding: 32px;
            border-radius: 64px;
            text-align: center;
            background-color: change-color($color: $bg, $alpha: 0.25);
            font-size: 16pt;

            br {
                margin-bottom: 1em;
            }

            * {
                z-index: 1;
            }

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .rule-area {
                padding: 17px 40px;
                width: 100%;
                gap: 12px;

                .rule {
                    flex-grow: 1;
                    height: 1px;
                    background-color: #A7A7A7;
                }
            }

            .timer-area {
                width: 100%;
            }

            .MuiButton-root {
                width: 100%;
                height: 100%;
            }
        }

        .title-area>h1 {
            font-size: 80pt;
            color: change-color($color: $bg, $lightness: 12%);
            background: linear-gradient(to left, change-color($color: $primary, $saturation: 80%, $lightness: 50%), $secondary);
            background-clip: text;
            -webkit-text-stroke: 4px transparent;
            margin: 0;
            line-height: 1;
        }

        .wallet-area {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 24px;
        }
    }

    .empty {
        display: block;
    }


    .hor-cap {
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 200px;
    }

    .ver-cap {
        position: absolute;
        left: 0px;
        right: 0px;
        height: 85px;
    }

    .left-fade {
        left: 0px;
        background-color: transparent;
        background-image: linear-gradient(to right, $black-color , transparent);
    }

    .right-fade {
        right: 0px;
        background-color: transparent;
        background-image: linear-gradient(to left, $black-color, transparent);;
    }

    .bottom-fade {
        bottom: 0px;
        background-color: transparent;
        background-image: linear-gradient(to top, $bg, transparent);
    }
}
