@import 'src/theme';

#mint-dialog {
    .MuiPaper-root {
        background-color: change-color($color: $secondary, $saturation: 30%, $lightness: 20%);
        padding-bottom: 16px;

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .MuiTextField-root {
            width: 100%;
        }

        .MuiButton-root {
            width: 100%;
        }
    }

    @media screen and (max-width: 1024px) {
        .MuiPaper-root {
            width: 100%;

            p {
                width: initial;
                max-width: initial;
            }
        }
    }
}
