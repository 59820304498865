@import 'src/theme';

#wallet-connector {
    .MuiPaper-root {
        background-color: change-color($color: $bg, $saturation: 30%, $lightness: 30%);
        padding-bottom: 16px;
    }
    .MuiButton-root {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 16px;

        img {
            width: 24px;
        }
    }
}
