@import 'src/theme';

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16pt;
  color: $text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

h2 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

h3 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: change-color($color: $primary, $saturation: 50%, $lightness: 60%)
}

a:hover {
  color: change-color($color: $primary, $saturation: 70%, $lightness: 50%)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

Button {
  border-radius: 50em;
}
