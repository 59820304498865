.footer-area {
    margin-top: 200px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .col {
            display: flex;
            flex-direction: column;
        }
    }
}
